import { useEffect } from "react";
import Mapimg from "../image/map.png";
import Locationimg from "../image/maps.png";
import AOS from "aos";
import "aos/dist/aos.css";

function Map() {
    useEffect(() => {
        AOS.init({
            duration: 1000,
            once: false,
        });

        const handleScroll = () => {
            AOS.refresh();
        };

        window.addEventListener("scroll", handleScroll);
        return () => window.removeEventListener("scroll", handleScroll);
    }, []);

    return (
        <div className="Map-wrapper">
            <div className="map-line" data-aos="slide-left" data-aos-delay="500" data-aos-anchor=".map-heading"></div>
            
            <div className="map-heading">
                Our focus is <span className="theme-text">driving scalable</span>
                <span className="theme-text"> growth and success</span> across
                early stage <span className="theme-text">Southeast Asia</span> businesses
            </div>
            
            <div className="map-container">
                <div className="map-main">
                    {/* Location icons with different delays for sequential appearance */}
                    <div className="locatn-1" data-aos="fade-up" data-aos-delay="300">
                        <img src={Locationimg} alt="Location" className="img-fluid" width="50" height="60" />
                    </div>
                    <div className="locatn-2" data-aos="fade-up" data-aos-delay="600">
                        <img src={Locationimg} alt="Location" className="img-fluid" width="50" height="60" />
                    </div>
                    <div className="locatn-3" data-aos="fade-up" data-aos-delay="900">
                        <img src={Locationimg} alt="Location" className="img-fluid" width="50" height="60" />
                    </div>
                    <div className="locatn-4" data-aos="fade-up" data-aos-delay="1200">
                        <img src={Locationimg} alt="Location" className="img-fluid" width="50" height="60" />
                    </div>
                    <div className="locatn-5" data-aos="fade-up" data-aos-delay="1500">
                        <img src={Locationimg} alt="Location" className="img-fluid" width="50" height="60" />
                    </div>
                    <div className="locatn-6" data-aos="fade-up" data-aos-delay="1700">
                        <img src={Locationimg} alt="Location" className="img-fluid" width="50" height="60" />
                    </div>

                    {/* Map Image */}
                    <img src={Mapimg} alt="Map" className="img-fluid map-imgg" width="400px" height="400px" />
                </div>
            </div>
        </div>
    );
}

export default Map;
