import { useState, useEffect } from "react";
import Slider from "react-slick";
import AOS from "aos";
import "aos/dist/aos.css";
import "../css/slick.css";
import "../css/slick-theme.css";
import Bannerimg from "../image/banner-1.png";
import BannerLeaf from "../image/banner-leaf.png";
import Banner1 from "../image/banner-BANGKOK.png";
import Banner2 from "../image/banner-HO CHI MINH CITY.png";
import Banner3 from "../image/banner-JAKARTA.png";
import Banner4 from "../image/banner-KUALA LUMPUR.png";
import Banner5 from "../image/banner-MANILA.png";
import Banner6 from "../image/banner-mumbai.png";
import Leaf from "../image/leaf.png";

function Banner() {
    const [activeIndex, setActiveIndex] = useState(0);

    useEffect(() => {
        AOS.init({
            duration: 1000,
            once: false,
        });
    }, []);

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        arrows: false,
        beforeChange: (oldIndex, newIndex) => setActiveIndex(newIndex),
    };

    const slides = [
        { img: Bannerimg, city: "SINGAPORE" },
        { img: Banner1, city: "BANGKOK" },
        { img: Banner2, city: "HO CHI MINH CITY" },
        { img: Banner3, city: "JAKARTA" },
        { img: Banner4, city: "KUALA LUMPUR" },
        { img: Banner5, city: "MANILA" },
        { img: Banner6, city: "MUMBAI" },
    ];

    return (
        <div className="banner-wrapper pt-1">
            <Slider {...settings}>
                {slides.map((slide, index) => (
                    <div className="position-relative" key={index}>
                        <div className="banner-line"></div>
                        <div className="banner-leaf">
                            <img src={BannerLeaf} alt="BannerLeaf" className="img-fluid" width="160" />
                        </div>
                        <div className="container">
                            <div className="banner-text">
                                <div 
                                    key={`text-1-${activeIndex}`} // Force re-render on slide change
                                    className={`mb-1 ${activeIndex === index ? "aos-init" : ""}`} 
                                    data-aos={activeIndex === index ? "fade-right" : ""} 
                                    data-aos-delay="500"
                                >
                                    <div>Empowering <span className="font-medium ml-1">Visionary Founders.</span></div>
                                </div>
                                <div 
                                    key={`text-2-${activeIndex}`} // Force re-render on slide change
                                    className={activeIndex === index ? "aos-init" : ""} 
                                    data-aos={activeIndex === index ? "fade-right" : ""} 
                                    data-aos-delay="900"
                                >
                                    <div>Shaping the <span className="font-medium">Future of Southeast Asia.</span></div>
                                </div>
                            </div>
                        </div>
                        <div className="banner-image">
                            <img src={slide.img} alt="Bannerimg" width="93%" className="img-fluid" />
                            <div className="container banner-image-text">
                                <img src={Leaf} alt="Leaf" className="img-fluid" width="40" />
                                <span className="ml-3">{slide.city}</span>
                            </div>
                        </div>
                    </div>
                ))}
            </Slider>
        </div>
    );
}

export default Banner;
