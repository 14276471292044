import { useState, useRef, useEffect } from "react";
import Header from "../layout/header";
import Footer from "../layout/footer";
import Banner from "../braxton/home-banner";
import Arrow from "../image/arrow.png";
import img1 from "../image/img.png";
import img2 from "../image/img1.png";
import img3 from "../image/img2.png";
import AOS from "aos";
import "aos/dist/aos.css";
function Investment() {
  useEffect(() => {
    AOS.init({
      duration: 1000, 
      once: true, 
    });
})
  return (
    <div className="main_wrapper">
      <Header />
      <div className="main_container">
        <div className="sub-banner">
          <div className="container">
            <div className="sub-banner-head">
              Empowering Founders.<br/>
              Transforming Society.
            </div>
            <div className="d-flex align-items-center">
              <div className="mr-4">
                <img src={Arrow} alt="Arrow" className="img-fluid" />
              </div>
              <div className="sub-banner-content">Our investment focuses on three key verticals, maximizes returns in
                fast growing industries, and creates meaningful impact across
                Southeast Asia</div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="investment-wrapper">
           
            <div className="row investment-pading">
              <div className="col-lg-4 col-md-6 col-12 px-lg-4">
                <div class="card shadow-sm" data-aos="fade-up" data-aos-delay="300">
                  <div className="investment-bg">
                    <img src={img1} alt="img1" className="img-fluid p-3" />
                  </div>

                  <div class="card-body">
                    <ul className="investment-list">

                      <li> Environmental and
                        social benefits</li>
                      <li>Building recycling
                        networks and circular economies</li>
                      <li>Supporting women
                        founders</li>
                      <li>Sustainable agriculture</li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-12 px-lg-4">
                <div class="card shadow-sm mb-5" data-aos="fade-up" data-aos-delay="1100">
                  <div className="investment-bg">
                    <img src={img2} alt="img1" className="img-fluid p-3" />
                  </div>

                  <div class="card-body">
                    <ul className="investment-list">
                      <li>   Shrink the $500bn
                        protection gap in
                        emerging asia</li>
                      <li>Improve individual
                        protection and wellness</li>
                      <li>Embed insurance to increase coverage</li>
                      <li>Create healthier and wealthier societies with protection, health, and wellness products and services</li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-12 px-lg-4">
                <div class="card shadow-sm" data-aos="fade-up" data-aos-delay="2100">
                  <div className="investment-bg">
                    <img src={img3} alt="img1" className="img-fluid p-3" />
                  </div>

                  <div class="card-body">
                    <ul className="investment-list">
                      <li>
                        Better health outcomes
                      </li>
                      <li>Increased access to
                        health services</li>
                      <li>Reduced cost of
                        healthcare</li>
                      <li>Improved lifestyle and
                        mental outcomes for individuals</li>
                    </ul>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
      <Footer />
    </div>
  );
}

export default Investment;
